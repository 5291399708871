import { createStyles, withStyles } from '@material-ui/core'
import React from 'react'
import { FeatureContainer } from '../../../../../../infrastructure/feature'
import { muiOptions } from '../../../../../../infrastructure/materialUiThemeProvider'
import { SapButton } from '../../../../../../infrastructure/sap/components/sapButton'
import { MovementType, SapFlowType } from '../../../../stockModels'
import { StockMovementContainer } from '../../../stockMovementStore'
import { getSapLabel, getSapTooltip, highlightRequiredFields, isEnabled, isValidated, SapTypes, isDisplayable, isPurchaseOrderValidated } from './formRequirements'
import { handleSapCall } from '../sapHandlers'

export type ButtonsProps = {
    className?: string
    classes: any
    isMainSapFlow: boolean
}

function _CreateSapMovementButtons({ className, classes, isMainSapFlow }: ButtonsProps) {
    let store = StockMovementContainer.useContainer()
    let sapFlow = store.getSapFlow(isMainSapFlow)

    const allowedMovementTypes = [
        MovementType.Sale,
        MovementType.Transfer,
        MovementType.StatusChange,
        MovementType.Rebranding,
        MovementType.Purchase,
        MovementType.Gains,
        MovementType.Losses
    ]

    if (sapFlow.movementType == null || allowedMovementTypes.indexOf(sapFlow.movementType) === -1)
        return (<></>)

    let featureContainer = FeatureContainer.useContainer()
    let buttonOptions = getButtonOptions()

    return (
        <div className={`${classes.buttonsContainer} ${className || ''}`}>
            {buttonOptions
                .filter(option =>
                    option.movementTypes.includes(sapFlow.movementType!) &&
                    (!option.sapFlowType || option.sapFlowType === sapFlow.sapFlowType))
                .filter(option => isDisplayable(option.type, featureContainer, !!store.associatedDeal))
                .map(option => (
                    <CreateButton key={option.type} type={option.type} isMainSapFlow={isMainSapFlow} />
                ))}
        </div>
    )
}

export function shouldRetryReleaseValidation(type: SapTypes): boolean {
    return type === 'purchase501' || type === 'poRelease'
}

type CreateButtonProps = {
    type: SapTypes
    isMainSapFlow: boolean
}

function CreateButton({ type, isMainSapFlow }: CreateButtonProps) {
    let store = StockMovementContainer.useContainer()
    let onClick = async () => {
        await store.trySave()
        await handleSapCall(type, store.stockMovement,
            async () => {
                await store.load(store.stockMovement.id);
            })
    }

    let sapFlow = store.getSapFlow(isMainSapFlow)

    return (
        <SapButton
            label={getSapLabel(type, sapFlow)}
            tooltip={getSapTooltip(type, sapFlow)}
            onHelpClick={() => highlightRequiredFields(type, isMainSapFlow, store)}
            onClick={onClick}
            validated={isValidated(type, sapFlow)}
            disabled={!isEnabled(type, store.stockMovement, sapFlow)} />
    )
}

//This method should be coherent with the method stockBoardStore > mapToSapType
const getButtonOptions: () => ButtonOption[] = () => [
    { movementTypes: [MovementType.Sale], type: 'sale' },
    { movementTypes: [MovementType.Sale], type: 'soDn' },
    { movementTypes: [MovementType.Sale], type: 'soGoodsIssue' },
    { movementTypes: [MovementType.Purchase], type: 'purchase501' },
    { movementTypes: [MovementType.Purchase], type: 'poRelease' },
    { movementTypes: [MovementType.Rebranding], type: 'rebranding309' },
    { movementTypes: [MovementType.Gains], type: 'gains' },
    { movementTypes: [MovementType.Losses], type: 'losses' },
    { movementTypes: [MovementType.Transfer, MovementType.StatusChange], type: 'sto', sapFlowType: 'sto' },
    { movementTypes: [MovementType.Transfer, MovementType.StatusChange], type: 'stoDn', sapFlowType: 'sto' },
    { movementTypes: [MovementType.Transfer, MovementType.StatusChange], type: 'stoGoodsIssue', sapFlowType: 'sto' },
    { movementTypes: [MovementType.Transfer, MovementType.StatusChange], type: 'migo', sapFlowType: 'sto' },
    { movementTypes: [MovementType.Transfer, MovementType.StatusChange], type: 'directTransfer', sapFlowType: 'direct301' },
    { movementTypes: [MovementType.Purchase], type: 'poMigo' },
]

type ButtonOption = {
    movementTypes: MovementType[]
    type: SapTypes
    sapFlowType?: SapFlowType
}

let styles = _ =>
    createStyles({
        buttonsContainer: {
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between'
        }
    })

export let CreateSapMovementButtons = withStyles(styles, muiOptions)(_CreateSapMovementButtons)