import moment from 'moment'
import React, { Fragment, RefObject, useEffect, useState, useRef, useImperativeHandle } from 'react'
import { withStyles, createStyles, Table, TableHead, TableRow, TableCell, TableBody, IconButton, TableFooter, Dialog, DialogTitle, DialogContent, DialogActions, Tooltip, Typography } from '@material-ui/core';
import { KeyboardArrowDown, KeyboardArrowRight } from '@material-ui/icons'
import { defaultColors, muiOptions, MuiProps } from '../../infrastructure/materialUiThemeProvider'
import { t } from '../../infrastructure/i18nextHelper'
import * as api from '../../infrastructure/api'
import { snackbars } from '../../infrastructure/snackbars'
import { hasChannelSalesManagerClaimWithScope } from '../../infrastructure/signIn/userContext'
import { Button, Link, NumberField } from '../common/customComponents'
import { tableStyles, ColumnFilterField } from '../common/components/table/table'
import { MktSaleDate, MktSaleForecastShipToDetails, MktSaleMovement, MktSalesComment, ShipToDetailsFilters, defaultShipToDetailsFilters } from './models'
import { MktSalesContainer } from './mktSalesStore'
import { hasFeature } from '../../infrastructure/feature'
import * as Icons from '@material-ui/icons'
import { Guid } from '@guid'
import { popupNavigator } from '../../infrastructure/popupNavigator'
import { DealFilter, DealMovementType, SapCounterparty } from '../deals/dealModels'
import { navigateTo } from '../../infrastructure/navigation'
import { DealContainer } from '../deals/dealEdit/dealEditStore'
import { closedColor, frozenColor, openedColor } from '../stock/actualStockEdit/accountingPeriod/accountingPeriodDialog';
import { MovementStatusChip } from '../stock/statusChip';
import { MktSalesCommentDialog } from './_mktSalesCommentDialog';

type volumeType = 'siteQuantity' | 'forecastQuantity' | 'plannedQuantity';
let shipToDetailsDialog: RefObject<{ open: (mktSale: MktSaleDate, movementIndex?: number | null) => void }> | null = null
let mktSalesCommentDialogRef: RefObject<{ open: (content: MktSalesComment) => void }> | null = null

function _MktSalesTable({ classes }: MuiProps) {
    let store = MktSalesContainer.useContainer()
    let [modifiedMktSaleMovements, setModifiedMktSaleMovements] = useState<MktSaleMovement[]>([])
    let [isCommentDialogOpen, setIsCommentDialogOpen] = useState<boolean>(false)
    let [mktSalesComment, setMktSalesComment] = useState<MktSalesComment | null>(null)

    mktSalesCommentDialogRef = useRef<{ open: (content: MktSalesComment) => void }>(null)

    useImperativeHandle(mktSalesCommentDialogRef, () => ({
        open: (content: MktSalesComment) => {
            setMktSalesComment(content)
            setIsCommentDialogOpen(true)
        }
    }))

    useEffect(() => {
        if (!store.tableEditMode.isOn)
            saveEditedLines()
    }, [store.tableEditMode.isOn])

    let onChangeVolume = (mvt: MktSaleMovement, prop: volumeType, value: number | null) => {
        mvt[prop] = (value === null || isNaN(value))
            ? value
            : +value

        handleModifiedMktSalesMovementToSave(mvt, prop)
        handleMktSalesMovementState(mvt)
    }

    let handleModifiedMktSalesMovementToSave = (mvt: MktSaleMovement, prop: volumeType) => {
        let modifiedMktSalesMvtIndex = modifiedMktSaleMovements.findIndex(x => x.id === mvt.id)
        if (modifiedMktSalesMvtIndex >= 0) {
            if ((store.unchangedMktSaleMovements.find(x => x.id === mvt.id)?.[prop] === null)
                && (mvt[prop] === null || isNaN(mvt[prop]!)))
                modifiedMktSaleMovements.splice(modifiedMktSalesMvtIndex, 1)
            else
                modifiedMktSaleMovements[modifiedMktSalesMvtIndex] = mvt
        }
        else {
            modifiedMktSaleMovements.push(mvt)
        }
    }

    let handleMktSalesMovementState = (mvt: MktSaleMovement) => {
        var movements = store.mktSaleDates.flatMap(x => x.movements)

        let mktSaleMvtIndex = movements.findIndex(x => x.id === mvt.id)

        if (mktSaleMvtIndex >= 0) {
            movements[mktSaleMvtIndex] = mvt
            store.changeMktSaleMovements(movements)
        }
    }

    let quantityRoundedSumToString =
        (property: (mksSaleDate: MktSaleDate) => (number | null | undefined)): number => Math.round(store.mktSaleDates
            .map(x => property(x) ?? 0)
            .reduce((previousValue, currentValue) => previousValue + currentValue, 0))

    let snapshotVolumeTotal = quantityRoundedSumToString(x => x.snapshotVolume);
    let forecastQuantityTotal = quantityRoundedSumToString(x => x.forecastQuantity);
    let plannedQuantityTotal = quantityRoundedSumToString(x => x.plannedQuantity);
    let siteQuantityTotal = quantityRoundedSumToString(x => x.siteQuantity);
    let sapQuantityTotal = quantityRoundedSumToString(x => x.sapQuantity);
    let quantityTotal = quantityRoundedSumToString(x => x.quantity);

    async function saveEditedLines() {
        if (modifiedMktSaleMovements.length === 0) return
        try {
            await api.post("stock/movement/mktsale", modifiedMktSaleMovements)
            snackbars.success(t('httpSuccess.actualSalesSaved'))

            await store.loadMktSaleMovements()
            setModifiedMktSaleMovements([])
        }
        catch (err) { throw err }
    }

    let shouldColorSiteQuantity = hasFeature('AccountingPeriod')
    function getApStatusClassName(accountingPeriodStatus: string | null) {
        if (!shouldColorSiteQuantity) return ''
        if (accountingPeriodStatus === 'Closed') return classes.closedField
        if (accountingPeriodStatus === 'Opened') return classes.openedField
        if (accountingPeriodStatus === 'Frozen') return classes.frozenField
        return classes.mixedField
    }

    let hasActionIcons = hasFeature('MktSalesForecastShipTo') || hasFeature('MktSalesDemandManagement')

    let saveMktSalesComment = async (arg: MktSalesComment) => {
        await api.post('stock/movement/mktsale/comment', arg)
        await store.loadMktSaleMovements()
    }

    let closeMktSalesComment = () => {
        setIsCommentDialogOpen(false)
        setMktSalesComment(null)
    }

    return (
        <div className={classes.root}>
            <Table stickyHeader aria-label='sticky table' >
                <TableHead className={classes.tableHeader}>
                    <TableRow>
                        <TableCell className={classes.cellPadding}>
                            <IconButton className={classes.toggleButton} onClick={() => store.toggleAllMktSaleDate()}>
                                {store.allMktSaleDatesSelected ? <KeyboardArrowRight /> : <KeyboardArrowDown />}
                            </IconButton>
                        </TableCell>
                        <TableCell className={classes.cellPadding}>{t('mktSales.table.date')}</TableCell>
                        <TableCell className={classes.cellPadding}>{t('mktSales.table.product')}</TableCell>
                        <TableCell className={classes.cellPadding}>{t('mktSales.forecast.company')}</TableCell>
                        <TableCell className={classes.cellPadding}>{t('mktSales.forecast.site')}</TableCell>
                        <TableCell className={classes.cellPadding}>{t('mktSales.table.customerSegment')}</TableCell>
                        <TableCell className={classes.cellRightPadding}>{t('mktSales.table.snapshotVolume', { unit: store.unit })}</TableCell>
                        <TableCell className={classes.cellRightPadding}>{t('mktSales.table.forecastQuantity', { unit: store.unit })}</TableCell>
                        {hasFeature('MktSalesDemandManagement') && <TableCell className={classes.cellRightPadding}>{t('mktSales.table.plannedQuantity', { unit: store.unit })}</TableCell>}
                        {hasFeature('MktSalesSiteVolume') && <TableCell className={classes.cellRightPadding}>{t('mktSales.table.siteQuantity', { unit: store.unit })}</TableCell>}
                        <TableCell className={classes.cellRightPadding}>{t('mktSales.table.sapQuantity', { unit: store.unit })}</TableCell>
                        <TableCell className={classes.cellRightPadding}>{t('mktSales.table.quantity', { unit: store.unit })}</TableCell>
                        <TableCell className={classes.cellPadding}>{t('mktSales.table.status')}</TableCell>
                        {hasFeature('MktSalesDemandManagement')
                            ? <TableCell className={classes.rightTableCell} align={'right'}></TableCell>
                            : undefined}
                        {hasFeature('MktSalesForecastShipTo')
                            ? <TableCell className={classes.rightTableCell} align={'right'}></TableCell>
                            : undefined}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {store.mktSaleDates.map(mktSaleDate =>
                        <Fragment key={mktSaleDate.date}>
                            <TableRow className={hasActionIcons ? classes.mainlineHover : ''}>
                                <TableCell className={classes.cellPadding}>
                                    <IconButton className={classes.toggleButton} onClick={() => store.toggleMktSaleDate(mktSaleDate.date)}>
                                        {mktSaleDate.isOpen ? <KeyboardArrowRight /> : <KeyboardArrowDown />}
                                    </IconButton>
                                </TableCell>
                                <TableCell className={classes.cellPadding}>{mktSaleDate.date ? moment(mktSaleDate.date).format('MM/DD/YYYY') : ''}</TableCell>
                                <TableCell className={classes.cellPadding}></TableCell>
                                <TableCell className={classes.cellPadding}></TableCell>
                                <TableCell className={classes.cellPadding}></TableCell>
                                <TableCell className={classes.cellPadding}></TableCell>
                                <TableCell className={classes.cellRightPadding}>{mktSaleDate.snapshotVolume !== null ? Math.round(mktSaleDate.snapshotVolume).toString() : null}</TableCell>
                                <TableCell className={classes.cellRightPadding}>{mktSaleDate.forecastQuantity !== null ? Math.round(mktSaleDate.forecastQuantity).toString() : null}</TableCell>
                                {hasFeature('MktSalesDemandManagement') && <TableCell className={classes.cellRightPadding}>{mktSaleDate.plannedQuantity !== null ? Math.round(mktSaleDate.plannedQuantity).toString() : null}</TableCell>}
                                {hasFeature('MktSalesSiteVolume') && <TableCell className={classes.cellRightPadding + ' ' + getApStatusClassName(mktSaleDate.accountingPeriodStatus)}>
                                    {mktSaleDate.siteQuantity !== null ? Math.round(mktSaleDate.siteQuantity).toString() : null}</TableCell>}
                                <TableCell className={classes.cellRightPadding}>{mktSaleDate.sapQuantity !== null ? Math.round(mktSaleDate.sapQuantity).toString() : null}</TableCell>
                                <TableCell className={classes.cellRightPadding}>{mktSaleDate.quantity !== null ? Math.round(mktSaleDate.quantity).toString() : null}</TableCell>
                                <TableCell className={classes.cellPadding}>{<MovementStatusChip status={mktSaleDate.status} />}</TableCell>
                                {hasFeature('MktSalesDemandManagement')
                                    ? <TableCell className={classes.rightTableCell} align={'right'}></TableCell>
                                    : undefined}
                                <TableCellShipTo classes={classes} mktSale={mktSaleDate} movementIndex={null} />
                            </TableRow>
                            {mktSaleDate.isOpen && mktSaleDate.movements.map((x, index) =>
                                <TableRow key={x.id} className={classes.subLine + ' ' + (hasActionIcons ? classes.sublineHover : '')}>
                                    <TableCell className={classes.cellPadding}></TableCell>
                                    <TableCell className={classes.cellPadding}></TableCell>
                                    <TableCell className={classes.cellPadding}>{store.products.find(p => p.id == x.productId)?.code}</TableCell>
                                    <TableCell className={classes.cellPadding}>{store.companies.find(c => c.code == x.company)?.name}</TableCell>
                                    <TableCell className={classes.cellPadding}>{store.sites.find(s => s.code == x.site)?.name}</TableCell>
                                    <TableCell className={classes.cellPadding}>{x.customerSegment}</TableCell>
                                    <TableCell className={classes.cellRightPadding}>{x.snapshotVolume !== null ? Math.round(x.snapshotVolume).toString() : null}</TableCell>
                                    <TableCell className={classes.cellRightPadding}>
                                        {store.tableEditMode.isOn && hasChannelSalesManagerClaimWithScope(x.customerSegment) && (mktSaleDate.date ? moment(mktSaleDate.date).diff(moment(), 'day') >= 0 : false) && hasFeature('MktSalesEditForecastVolume')
                                            ? <NumberField disableNewStyle onChange={value => onChangeVolume(x, 'forecastQuantity', value)} text={x.forecastQuantity} />
                                            : x.forecastQuantity !== null ? Math.round(x.forecastQuantity).toString() : null}
                                    </TableCell>
                                    {hasFeature('MktSalesDemandManagement') &&
                                        <TableCell className={classes.cellRightPadding}>
                                            {store.tableEditMode.isOn
                                                && hasChannelSalesManagerClaimWithScope(x.customerSegment)
                                                && hasFeature('MktSalesDemandManagement')
                                                && (hasFeature('AllowPastMktSalesDemandModification') || moment(mktSaleDate.date).startOf('day').isSameOrAfter(moment().startOf('day')))
                                                ? <NumberField disableNewStyle onChange={value => onChangeVolume(x, 'plannedQuantity', value)} text={x.plannedQuantity} />
                                                : x.plannedQuantity !== null ? Math.round(x.plannedQuantity).toString()
                                                    : null}
                                        </TableCell>}
                                    {hasFeature('MktSalesSiteVolume') &&
                                        <TableCell className={classes.cellRightPadding + ' ' + getApStatusClassName(x.accountingPeriodStatus)}>
                                            {store.tableEditMode.isOn
                                                && hasChannelSalesManagerClaimWithScope(x.customerSegment)
                                                && hasFeature('MktSalesSiteVolume')
                                                && !(moment(mktSaleDate.date).startOf('day').isAfter(moment().startOf('day')))
                                                ? <NumberField disableNewStyle onChange={value => onChangeVolume(x, 'siteQuantity', value)} text={x.siteQuantity} />
                                                : x.siteQuantity !== null ? Math.round(x.siteQuantity).toString()
                                                    : null}
                                        </TableCell>}
                                    <TableCell className={classes.cellRightPadding}>
                                        {x.sapQuantity !== null ? Math.round(x.sapQuantity).toString() : null}
                                    </TableCell>
                                    <TableCell className={classes.cellRightPadding}>
                                        {x.quantity !== null ? Math.round(x.quantity).toString() : null}
                                    </TableCell>
                                    <TableCell className={classes.cellPadding}><MovementStatusChip status={x.status} /></TableCell>
                                    {hasFeature('MktSalesDemandManagement') && <TableCellComment classes={classes} mktSale={x} />}
                                    <TableCellShipTo classes={classes} mktSale={mktSaleDate} movementIndex={index} />
                                </TableRow>)
                            }
                        </Fragment>
                    )}
                </TableBody>
                {store.mktSaleDates.length > 1 ?
                    <TableFooter className={classes.footer}>
                        <TableRow>
                            <TableCell colSpan={6} className={classes.cellPadding}></TableCell>
                            <TableCell className={classes.cellRightPadding}>
                                <b>{snapshotVolumeTotal}</b>
                                {`(${t('components.table.sum')})`}
                            </TableCell>
                            <TableCell className={classes.cellRightPadding}>
                                <b>{forecastQuantityTotal}</b>
                                {`(${t('components.table.sum')})`}
                            </TableCell>
                            {hasFeature('MktSalesDemandManagement') &&
                                <TableCell className={classes.cellRightPadding}>
                                    <b>{plannedQuantityTotal}</b>
                                    {`(${t('components.table.sum')})`}
                                </TableCell>
                            }
                            {hasFeature('MktSalesSiteVolume') &&
                                <TableCell className={classes.cellRightPadding}>
                                    <b>{siteQuantityTotal}</b>
                                    {`(${t('components.table.sum')})`}
                                </TableCell>
                            }
                            <TableCell className={classes.cellRightPadding}>
                                <b>{sapQuantityTotal}</b>
                                {`(${t('components.table.sum')})`}
                            </TableCell>
                            <TableCell className={classes.cellRightPadding}>
                                <b>{quantityTotal}</b>
                                {`(${t('components.table.sum')})`}
                            </TableCell>
                            <TableCell className={classes.cellPadding}></TableCell>
                            {hasFeature('MktSalesForecastShipTo')
                                ? <TableCell className={classes.rightTableCell}></TableCell>
                                : <></>}
                        </TableRow>
                    </TableFooter> : null}
            </Table>
            {hasFeature('MktSalesForecastShipTo') &&
                <ShipToDetails classes={classes} />}

            {hasFeature('MktSalesDemandManagement') &&
                <MktSalesCommentDialog
                    isOpen={isCommentDialogOpen}
                    onSave={saveMktSalesComment}
                    entity={mktSalesComment}
                    onClose={closeMktSalesComment} />}
        </div >
    )
}

function ShipToDetails(props) {
    let store = MktSalesContainer.useContainer()
    let [isOpen, setIsOpen] = useState<boolean>(false)
    let [shipTos, setShipTos] = useState<MktSaleForecastShipToDetails[] | null>(null)
    let [header, setHeader] = useState<string>('')
    let [filters, setFilters] = useState<ShipToDetailsFilters>(defaultShipToDetailsFilters)

    let dealStore = DealContainer.useContainer()
    shipToDetailsDialog = useRef<{ open: (mktSale: MktSaleDate, movementIndex?: number | null) => void }>(null)

    useImperativeHandle(shipToDetailsDialog, () => ({
        open: async (mktSale: MktSaleDate, movementIndex?: number | null) => {
            setShipTos(null)

            if (!hasFeature('MktSalesForecastShipTo')) return;
            let shipTos = await store.getShipTos(mktSale, movementIndex)
            await store.loadSapCounterPartys()

            setShipTos(shipTos.map(flattenDealReferences));
        }
    }))

    let handleShipToDetailsResponse = () => {
        if (!shipTos) return;
        if (shipTos.length === 0) {
            snackbars.info(t('mktSales.shipTo.notFound'))
            return
        }
        setHeader(t('mktSales.shipTo.header') + ' ' + moment(shipTos[0].date).format('MM/DD/YYYY'))
        setIsOpen(true)
    }

    useEffect(handleShipToDetailsResponse, [shipTos, header])

    let close = () => {
        setShipTos(null)
        setHeader('')
        setIsOpen(false)
    }

    let basicColumns = ['companyName', 'product', 'customerSegment', 'site', 'soldTo', 'soldToName', 'shipTo', 'shipToName', 'destination', 'flattenedDealReferences']

    let quantityColumns = ['snapshotVolume', 'forecastQuantity']
    if (hasFeature('MktSalesDemandManagement'))
        quantityColumns.push('plannedQuantity')

    quantityColumns = quantityColumns.concat(['siteQuantity', 'sapQuantity'])

    let columns = basicColumns.concat(quantityColumns)

    let sumQuantity =
        (property: (shipTo: MktSaleForecastShipToDetails) => (number | null | undefined)): number => Math.round(getItems().map(x => property(x) ?? 0).reduce((prev, curr) => prev + curr, 0))

    let isQuantityColumn = (x: string) => quantityColumns.indexOf(x) !== -1

    let columnsToFilter = Object.keys(filters)
    let filterByInput = (prop: string, input: string) => (x: MktSaleForecastShipToDetails) => (x[prop] ?? '').toLowerCase().contains(input.toLowerCase())

    let applyFilters = (shipToDetails: MktSaleForecastShipToDetails[], filters: ShipToDetailsFilters): MktSaleForecastShipToDetails[] => {
        for (let input in filters) {
            if (filters[input])
                shipToDetails = shipToDetails.filter(filterByInput(input, filters[input]))
        }
        return shipToDetails
    }

    let getItems = () => applyFilters(shipTos ?? [], filters)
    let flattenDealReferences = (x: MktSaleForecastShipToDetails): MktSaleForecastShipToDetails => ({
        ...x,
        flattenedDealReferences: Object.values(x.dealReferences).join(', ')
    })
    let openDeal = (dealId: Guid) => popupNavigator.open('deal', dealId)

    let openDealList = async (x: MktSaleForecastShipToDetails, refs: string[]) => {
        let date = moment(x.date).utc().format('MM/DD/YYYY')
        let dutyStatuss = store.channelCustomerSegments
            .filter(y => y.customerSegment === x.customerSegment)
            .map(y => y.dutyStatus)

        let counterpartys = store.sapCounterpartys.filter((y: SapCounterparty) => y.shipToCode === x.shipTo
            && y.shipToName === x.shipToName
            && y.companyCode === x.company
            && y.customerSegment === x.customerSegment)
            .map(y => y.counterpartyId)

        let dealFilters: DealFilter = {
            movementTypes: [DealMovementType.SALE],
            dateOn: date,
            displayPricingPeriodList: false,
            productIds: [x.productId],
            companys: [x.company],
            dutyStatuss: dutyStatuss,
            query: refs.length === 1 ? refs[0] : null,
            counterpartyIds: counterpartys,
            isDayPeriodMode: true,
            sites: [x.site]
        }

        dealStore.setFilters(dealFilters)
        navigateTo('/deals')
    }

    return (
        <Dialog
            open={isOpen}
            onClose={close}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
            fullWidth={true}
            maxWidth={'xl'}
        >
            <DialogTitle>{header} </DialogTitle>
            <DialogContent className={props.classes.dialogContent}>
                <Table stickyHeader aria-label='sticky table' className={props.classes.shipToDetailsTable}>
                    <TableHead>
                        <TableRow>
                            {columns.map(x => (
                                <TableCell key={x} className={isQuantityColumn(x) ? props.classes.cellRightPadding : props.classes.shipToTableHead}>{t(`mktSales.shipTo.table.${x}`, { unit: store.unit })}</TableCell>
                            ))}
                            <TableCell key={'openDealRef'} className={props.classes.shipToTableHead}></TableCell>
                        </TableRow>
                        <TableRow>
                            {columns.map(x => (
                                <TableCell key={x} className={props.classes.filterHeadCell}>
                                    {columnsToFilter.indexOf(x) !== -1 && (
                                        <ColumnFilterField classes={props.classes} filter={{
                                            value: filters[x],
                                            onChange: (value: string) => setFilters({ ...filters, [x]: value })
                                        }} />
                                    )}
                                </TableCell>
                            ))}
                            <TableCell key={'openDealRef'} className={props.classes.shipToTableHead}></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {getItems().map((shipToDetails) => {
                            let dealIds = Object.keys(shipToDetails.dealReferences)
                            let dealRefs = Object.values(shipToDetails.dealReferences)
                            return <TableRow key={shipToDetails.id} className={props.classes.mainlineHover}>
                                {columns.map(x => {
                                    if (x === 'flattenedDealReferences')
                                        return <TableCell key={x}>
                                            {dealIds.map((key, index) =>
                                                <React.Fragment>
                                                    <Link
                                                        onClick={() => openDeal(key)}>
                                                        {shipToDetails.dealReferences[key]}
                                                    </Link>
                                                    {index !== dealIds.length - 1 && ", "}
                                                </React.Fragment>
                                            )}
                                        </TableCell>
                                    return <TableCell key={x} className={isQuantityColumn(x) ? props.classes.cellRightPadding : null}>{shipToDetails[x]}</TableCell>
                                })}
                                <TableCell key={'openDealRef'} align={'right'}>
                                    {dealIds.length > 0 &&
                                        <div className={props.classes.lineContainer}>
                                            <Tooltip title={<Typography variant='body1'>{t('mktSales.shipTo.searchDeals')}</Typography>} placement='top'>
                                                <IconButton size={'small'} onClick={() => openDealList(shipToDetails, dealRefs)}><Icons.OpenInNewOutlined /></IconButton>
                                            </Tooltip>
                                        </div>}
                                </TableCell>
                            </TableRow>
                        })}
                    </TableBody>
                    {shipTos && shipTos.length > 1 &&
                        <TableFooter className={props.classes.footer}>
                            <TableRow>
                                <TableCell key={'basicColumn'} colSpan={basicColumns.length}></TableCell>
                                {quantityColumns.map(x =>
                                    <TableCell key={x} className={props.classes.cellRightPadding}>
                                        <b>{sumQuantity(y => y[x])}</b>
                                        {`(${t('components.table.sum')})`}
                                    </TableCell>
                                )}
                                <TableCell key={'openDealRef'}></TableCell>
                            </TableRow>
                        </TableFooter>}
                </Table>
            </DialogContent>
            <DialogActions className={props.classes.marginTop1em}>
                <Button onClick={() => setIsOpen(false)}
                    label={t('components.dialogClose')}
                    color='primary' />
            </DialogActions>
        </Dialog>
    )
}

function TableCellShipTo({ classes, mktSale, movementIndex }: { mktSale: MktSaleDate, movementIndex?: number | null } & MuiProps) {
    return (
        <>{hasFeature('MktSalesForecastShipTo')
            ? <TableCell className={classes.rightTableCell}>
                <div className={classes.lineContainer}>
                    <Tooltip title={<Typography variant='body1'>{t('mktSales.shipTo.openShipTo')}</Typography>} placement='top'>
                        <IconButton size={'small'} onClick={() => shipToDetailsDialog?.current?.open(mktSale, movementIndex)}><Icons.OpenInNewOutlined /></IconButton>
                    </Tooltip>
                </div>
            </TableCell>
            : undefined}
        </>
    )
}

function TableCellComment({ classes, mktSale }: { mktSale: MktSaleMovement } & MuiProps) {
    return (
        <TableCell className={classes.rightTableCell}>
            <div className={classes.lineContainer}>
                <IconButton size={'small'} onClick={() => mktSalesCommentDialogRef?.current?.open({ id: mktSale.id, comment: mktSale.comment })}>
                    {mktSale?.comment
                        ? <Tooltip title={< Typography variant='body1' > {t('mktSales.table.comments')}</Typography >} placement='top' >
                            <img src='/static/images/chat_icon.svg' className={classes.commentIcon} />
                        </Tooltip >
                        : <img src='/static/images/empty_chat_icon.svg' className={classes.commentIcon} width="10px" height="10px" />}
                </IconButton>
            </div>
        </TableCell>
    )
}

let styles = theme =>
    createStyles({
        root: {
            width: '100%',
            overflow: 'auto',
            paddingBottom: '1em'
        },
        tableHeader: {
            position: 'sticky',
            zIndex: 100
        },
        cellPadding: tableStyles.cellPadding,
        cellRightPadding: tableStyles.cellRightPadding,
        toggleButton: {
            margin: '-0.8em -0.5em',
            "& > svg": {
                width: '0.8em',
            }
        },
        footer: theme.overrides.MuiTableCell.footer,
        subLine: {
            backgroundColor: 'whitesmoke',
        },
        shipToButton: {
            cursor: 'pointer'
        },
        rightTableCell: {
            padding: 0,
            width: 0,
        },
        shipToTableHead: {
            padding: '10px 0 10px 8px',
        },
        shipToDetailsTable: {
            '& tr td': {
                padding: '8px !important'
            }
        },
        lineContainer: {
            direction: 'rtl',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            position: 'relative',
            whiteSpace: 'nowrap',
            float: 'right',
            textAlign: 'right',
            justifyContent: 'flex-end',
            background: 'white',
            borderRadius: '10px',
            opacity: 0,
            '& > *': {
                marginRight: '0.1em',
                '&:first-child': {
                    marginRight: 0
                }
            }
        },
        mainlineHover: {
            cursor: 'pointer',
            '&:hover': {
                backgroundColor: '#FBFBFB',
                '& $lineContainer': {
                    opacity: '1 !important'
                }
            }
        },
        sublineHover: {
            cursor: 'pointer',
            '&:hover': {
                backgroundColor: '#e6e6e6',
                '& $lineContainer': {
                    opacity: '1 !important'
                }
            }
        },
        dialogContent: {
            paddingTop: '0 !important',
            paddingBottom: '0 !important'
        },
        openedField: {
            color: openedColor
        },
        frozenField: {
            color: frozenColor
        },
        closedField: {
            color: closedColor
        },
        mixedField: {
            color: defaultColors.orange.light.color,
        },
        filterHeadCell: tableStyles.filterHeadCell,
        commentIcon: {
            height: '1em',
            width: '1em',
        }
    })

export let MktSalesTable = withStyles(styles, muiOptions)(_MktSalesTable)